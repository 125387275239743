import React from 'react'

import { useApolloClient } from '@apollo/client'
import { useApi } from '@core/api'
import { useMainState } from '@core/main-state'
import { addSnack } from '@core/snack/snack-state'
import { authorKeys } from '@pages/author/author-api'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { AiModel, Customer, FormValues } from '../main-types'
import * as queries from './admin-queries'
import type { AdminView } from './admin-views'

export type State = {
  open: boolean
  customers: Customer[] | null
  selectedCustomerId: string
  loading: boolean
  view: AdminView
  models: AiModel[] | null
}

export const initialState: State = {
  loading: false,
  open: false,
  customers: null,
  selectedCustomerId: '',
  view: 'SWITCH_CUSTOMER',
  models: null,
}

function reducer(state: State, action: Partial<State>): State {
  return { ...state, ...action }
}

const context = { role: 'internal_admin' }

export const useAdminSettings = () => {
  const api = useApi()
  const client = useApolloClient()
  const queryClient = useQueryClient()
  const [user, customer] = useMainState((state) => [state.user, state.customer])

  const [state, setState] = React.useReducer(reducer, {
    ...initialState,
    selectedCustomerId: customer?.id || '',
  })

  const loadCustomers = React.useCallback(() => {
    client
      .query({
        query: queries.GET_CUSTOMERS,
        context,
      })
      .then(({ data }) => {
        setState({ customers: data.customer })
      })
      .catch((error) => {
        addSnack({ message: error.message, severity: 'error' })
      })
  }, [])

  const switchCustomer = useMutation({
    mutationKey: ['switchCustomer'],
    mutationFn: async (customerId: string): Promise<{ status?: string; message?: string }> => {
      setState({ loading: true })
      await api.switchCustomer({ path: { customer_id: customerId } })
      setState({ loading: false })
      return { status: 'ok' }
    },
    onError: () => {
      const message = 'Failed to switch customer'
      addSnack({ message, severity: 'error' })
      setState({ loading: false })
      return { status: 'error', message }
    },
  })

  const handleSwitchCustomer = async (e: React.FormEvent) => {
    e.preventDefault()

    await switchCustomer.mutateAsync(state.selectedCustomerId)
  }

  const handleUpdateCustomer = async (values: FormValues) => {
    setState({ loading: true })

    try {
      const { data } = await client.mutate({
        mutation: queries.UPDATE_CUSTOMER_FEATURES,
        variables: { id: customer!.id, ...values },
        context,
      })

      if (!data?.customer) {
        throw new Error('Customer update failed')
      }

      setState({ loading: false })

      useMainState.setState((prev) => ({
        customer: {
          ...prev.customer!,
          ...data!.customer,
        },
      }))

      addSnack({ message: 'Customer updated', severity: 'success' })
    } catch (error) {
      addSnack({ message: error.message, severity: 'error' })
      setState({ loading: false })
    }
  }

  const handleAddCustomer = async (values: { name: string; identifier: string }) => {
    setState({ loading: true })

    try {
      await client.mutate({
        mutation: queries.ADD_CUSTOMER,
        variables: { ...values },
        context,
      })

      addSnack({ message: 'New customer added', severity: 'success' })
      setState({ loading: false })

      return {
        success: true,
      }
    } catch (error) {
      if (error.message.includes('customer_identifier_key')) {
        const message = 'Customer with this identifier already exists'

        return {
          success: false,
          reason: message,
        }
      }

      addSnack({
        message: error.message || 'Something went wrong',
        severity: 'error',
      })
      setState({ loading: false })

      return {
        success: false,
        reason: error.message,
      }
    }
  }

  const handleUpdateModel = async (model: AiModel) => {
    setState({ loading: true })
    try {
      await client.mutate({
        mutation: queries.UPDATE_AI_MODEL,
        variables: { id: model.id, isInternal: !model.isInternal },
        context,
      })
      setState({ loading: false })
    } catch (error) {
      addSnack({ message: error.message, severity: 'error' })
      setState({ loading: false })
    }
  }

  const handleClose = () => {
    setState({ open: false })

    queryClient.invalidateQueries({
      queryKey: authorKeys.aiModelList,
    })
  }

  return {
    state,
    setState,
    handleAddCustomer,
    handleSwitchCustomer,
    handleUpdateCustomer,
    handleUpdateModel,
    user,
    customer,
    loadCustomers,
    handleClose,
  }
}

export type AdminSettings = ReturnType<typeof useAdminSettings>
