import { gql, TypedDocumentNode } from '@apollo/client'

import type { AiModel, Customer } from '../main-types'

export const GET_CUSTOMERS: TypedDocumentNode<{ customer: Customer[] }> = gql`
  query getCustomers {
    customer(order_by: { name: asc }) {
      id
      name
      features
      createdAt: created_at
    }
  }
`
export const GET_MODELS: TypedDocumentNode<{ models: AiModel[] }> = gql`
  subscription getModels {
    models: ai_model(where: { is_archived: { _eq: false } }, order_by: { name: asc }) {
      id
      name
      isInternal: is_internal
      type
      internalName: internal_model_name
      createdAt: created_at
    }
  }
`
export const LIST_MODELS: TypedDocumentNode<{ models: AiModel[] }> = gql`
  query getModels {
    models: ai_model(where: { is_archived: { _eq: false } }, order_by: { name: asc }) {
      id
      name
      internalName: internal_model_name
    }
  }
`

export const ADD_CUSTOMER: TypedDocumentNode<
  { insert_customer_one: { id: string } },
  { name: string; identifier: string }
> = gql`
  mutation addCustomer($name: String!, $identifier: String!) {
    insert_customer_one(object: { name: $name, identifier: $identifier }) {
      id
    }
  }
`

type Params = { id: string; features: Customer['features']; hasOptionRationale: boolean }

export const UPDATE_CUSTOMER_FEATURES: TypedDocumentNode<
  { customer: Params & { updatedAt: string } },
  Params
> = gql`
  mutation updateCustomerFeatures($id: uuid!, $features: jsonb!, $hasOptionRationale: Boolean!) {
    customer: update_customer_by_pk(
      pk_columns: { id: $id }
      _set: { has_option_rationale: $hasOptionRationale, features: $features }
    ) {
      features
      hasOptionRationale: has_option_rationale
      updatedAt: updated_at
    }
  }
`

type ModelParams = { id: string; isInternal: boolean }

export const UPDATE_AI_MODEL: TypedDocumentNode<{ model: ModelParams }, ModelParams> = gql`
  mutation updateAiModel($id: uuid!, $isInternal: Boolean!) {
    model: update_ai_model_by_pk(pk_columns: { id: $id }, _set: { is_internal: $isInternal }) {
      id
      is_internal
    }
  }
`
