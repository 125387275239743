// eslint-disable-next-line no-restricted-imports
import * as generated from '@core/api/generated/services.gen'

const excluded = [
  'client',
  'cloneItem',
  'continueExportJob',
  'createAiModel',
  'createUser',
  'deleteAiModels',
  'deleteAuth0Users',
  'deleteFinetunes',
  'deleteUsers',
  'downloadExportedFile',
  'downloadItems',
  'exportItems',
  'generate',
  'generateItemSet',
  'generateRationales',
  'getCustomer',
  'getIntegration',
  'getItemMedia',
  'getItemMediaList',
  'importFinetunes',
  'listAiModels',
  'listAiModelsAdmin',
  'loginCallback',
  'loginCallback1',
  'possibleKeys',
  'proxyWolframImage',
  'queryWolfram',
  'removeProject',
  'resetCustomerData',
  'resetUserData',
  'runBiasCheck',
  'saveItem',
  'searchReferences',
  'switchCustomer',
  'testIntegration',
  'updateAiModelFlags',
  'updateUser',
  'uploadMediaToItem',
  'upsertIntegration',
] as const

type Excluded = (typeof excluded)[number]

// BffRoutes but without the excluded
export type BffRoutesAvailable = Exclude<keyof typeof generated, Excluded>

export const bffOptions = Object.keys(generated).filter(
  (key) => !excluded.includes(key as Excluded),
) as BffRoutesAvailable[]

export type BffFeatureFlags = {
  [key in BffRoutesAvailable]?: boolean
}
