import { gql, TypedDocumentNode } from '@apollo/client'

import type { ContentVersion } from './content-version-types'

export const ARCHIVE_ITEM: TypedDocumentNode<{ result: { id: string } }, { itemId: string }> = gql`
  mutation archiveItem($itemId: uuid!) {
    result: update_item_by_pk(pk_columns: { id: $itemId }, _set: { is_archived: true }) {
      id
    }
  }
`

export type UserInputUpdate = {
  where: { item_id: { _eq: string }; type: { _eq: 'USER_INPUT' } }
  _set: Partial<ContentVersion>
}

export type UpdateContentVersionsInput = {
  rootId: string
  // basic types
  items: {
    item_id: string
    comments?: any[]
    inputs?: Record<string, any>
    submodels?: string[]
    external_metadata?: Record<string, any>
    quality_metrics?: any[]
    content?: Record<string, any>
    item_bias?: Record<string, any>
    item_key_check?: Record<string, any>
  }[]
}

export const UPDATE_CONTENT_VERSIONS: TypedDocumentNode<
  { result: { ok: boolean; error?: string } },
  UpdateContentVersionsInput
> = gql`
  mutation updateContentVersions($rootId: uuid!, $items: [jsonb!]!) {
    result: update_content_versions(root_id: $rootId, items: $items) {
      ok
      error
    }
  }
`
