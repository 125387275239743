import { palette as paletteColor } from './color-palette'

export const common = { black: '#000000', white: '#ffffff' }
const { blue, green, neutral, purple, red, teal, yellow } = paletteColor
export const palette = paletteColor

export const background = {
  button: 'rgba(235, 236, 240, 0.6)',
  white: common.white,
  grey: '#fafbfc',
  paleGrey: '#f9f7f9',
  blueGrey: '#f4f5f7',
  progress: '#d5d7de',
  notification: '#4c9aff',
  successGreen: '#E3FCEF',
  hrLight: '#d8d8d8',
  balanceGreen: '#e2ffee',
  help2: '#5243aa',
  pattensBlue: '#deebff',
  itemVoteButton: 'rgba(33, 150, 243, 0.08)',
  vector: '#dfe1e5',
  orange: '#ff5630',
  textArea: '#f9f9f9',
  disabledCard: '#f4f5f7',
  disabledButton: '#f4f5f7',
  purple: '#6554c0',
}

export const linkBlue = '#0052cc'

export const text = {
  cadetBlue: '#a5adba',
  slate: '#505f79',
  slateBlue: '#172b4d',
  lightSlateGray: '#7a869a',
  indigo: '#253858',
  greyBlue: '#6b778c',
  greyDarkerBlue: '#56667d',
  silver: '#8993a4',
  blue: '#0065ff',
  grayishBlue: '#5e6c84',
  balanceGreen: '#006644',
  peacockBlue: '#0747a6',
  milanoRed: '#bf2600',
  red: '#de350b',
  disabled: '#00000042',
  linkBlue,
}

const actualWords = '#C0B6F2'
const mariGold = '#ffc400'
const paragraphs = '#d4066e'
const seafoamBlue = '#57d9a3'
const coleman = '#ff7452'
const aquaBlue = '#00c7e6'
const easy = '#ABF5D1'
const medium = '#FFF0B3'
const hard = '#FFBDAD'
const mediumDark = '#FF8B00'

export const itemSetColors = {
  ITEM_SET_B300: {
    main: blue.B300,
    contrastText: neutral.N000,
  },
  ITEM_SET_Y500: {
    main: yellow.Y500,
    contrastText: neutral.N800,
  },
  ITEM_SET_P075: {
    main: purple.P075,
    contrastText: neutral.N800,
  },
  ITEM_SET_R500: {
    main: red.R500,
    contrastText: neutral.N000,
  },
  ITEM_SET_G075: {
    main: green.G075,
    contrastText: neutral.N800,
  },
  ITEM_SET_T300: {
    main: teal.T300,
    contrastText: neutral.N800,
  },
  ITEM_SET_R075: {
    main: red.R075,
    contrastText: neutral.N800,
  },
  ITEM_SET_G500: {
    main: green.G500,
    contrastText: neutral.N000,
  },
  ITEM_SET_B075: {
    main: blue.B075,
    contrastText: neutral.N800,
  },
  ITEM_SET_Y075: {
    main: yellow.Y075,
    contrastText: neutral.N800,
  },
  ITEM_SET_P300: {
    main: purple.P300,
    contrastText: neutral.N000,
  },
  ITEM_SET_T075: {
    main: teal.T075,
    contrastText: neutral.N800,
  },
}

export const tag = {
  warning: { main: '#FFE380', contrastText: text.slateBlue },
  ametist: { main: '#9b59b6', contrastText: common.white },
  peterRiver: { main: '#3498db', contrastText: common.white },
  dusk: { main: '#42526e', contrastText: common.white },
  batch: { main: '#B3D4FF', contrastText: linkBlue },
  aquaBlue: { main: aquaBlue, contrastText: text.slateBlue },
  seafoamBlue: { main: seafoamBlue, contrastText: text.slateBlue },
  mariGold: { main: mariGold, contrastText: text.slateBlue },
  gradeLevelBlue: { main: '#74b9ff', contrastText: text.slateBlue },
  pinkRed: { main: '#f50057', contrastText: common.white },
  actualWords: { main: actualWords, contrastText: text.slateBlue },
  paragraphs: { main: paragraphs, contrastText: common.white },
  coleman: { main: coleman, contrastText: text.slateBlue },
  easy: { main: easy, avatarBg: text.balanceGreen, contrastText: text.slateBlue },
  medium: { main: medium, avatarBg: mediumDark, contrastText: text.slateBlue },
  hard: { main: hard, avatarBg: text.milanoRed, contrastText: text.slateBlue },
  clone: { main: background.purple, contrastText: common.white },
  STORY: { main: '#1E3A60', contrastText: common.white },
  MCQ: { main: text.blue, contrastText: common.white },
  HOT_TEXT: { main: text.blue, contrastText: common.white },
  FRQ: { main: '#79E2F2', contrastText: text.slateBlue },
  MPFRQ: { main: '#FFAB00', contrastText: text.slateBlue },
  ITEM_SET: { main: '#40739e', contrastText: common.white },
  ITEM_SET2: { main: '#34495e', contrastText: common.white },
  PASSAGE_BUILDER: { main: '#1E3A60', contrastText: common.white },
  LIKERT: { main: '#56667d', contrastText: common.white },

  ITEM_SET_BLACK_WHITE: { main: neutral.N010, contrastText: neutral.N900 },

  ...itemSetColors,
}

export const histogram = {
  colors: {
    'actual-words': actualWords,
    'word-count': actualWords,
    'number-of-sentences': mariGold,
    'type-token-ratio': mariGold,
    'number-of-paragraphs': paragraphs,
    'flesch-reading-ease': paragraphs,
    'flesch-kincaid-readability': seafoamBlue,
    'coleman-liau-index': coleman,
    'type-to-token-ratio': aquaBlue,
  },
  additionalColors: [actualWords, mariGold, paragraphs, seafoamBlue, coleman, aquaBlue],
  defaultColor: '#87b4df',
}

export const fgProgress = '#006EFE'

export const other = {
  hrLight: '#d8d8d8',
  scandalGreen: '#e3fcef',
  checkedGreen: '#00875a',
  ghostBlue: '#c1c7d0',
  fairPink: '#ffebe5',
  lightBlue: '#eef7fe',
  downriver: '#091e42',
  sliderDisabled: '#ebecf0',
  grayishBlueDark: '#4A5568',
}

export const suggestAnswer = {
  distractor: '#ffebe5  ',
  correct: '#e3fcef',
}

export const states = {
  success: '#36b37e',
  error: '#de350b',
  warning: '#ffab00',
  focus: '#4c9aff',
}

export const biasCheckStates = {
  PASSED: '#36b37e',
  FAILED: '#ff5630',
  SKIPPED: '#36b37e',
  EDITED: '#ffab00',
  NOT_STARTED: '#c1c7d0',
}

export const keyCheckStateColors = {
  DONE: '#36b37e',
  FAILED: '#ff5630',
  EDITED: '#ffab00',
  IN_PROGRESS: '#ffab00',
}
