/* eslint-disable no-param-reassign */
import _ from 'lodash'

import { routeEnter, routeLeave } from '@containers/main/main-slice'
import * as metadataThunk from '@containers/metadata/metadata-thunk'
import { CLOZE } from '@core/constants/content-type'
import { stripHTML } from '@core/utils/strip-html'
import { countWords } from '@core/utils/text'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import * as thunk from './author-cloze-thunk'
import { AnswerStatus, ItemCloze, ItemMCQ, State, StateLoaded } from './author-cloze-types'
import { getOptions } from './author-cloze-utils'

export const thunkActions = thunk

export function getInitialState(): State {
  return {
    initialized: false,
    contentTab: 0,
    sidebarTab: 0,
    notFound: false,
    items: null,
    // the current accordion opened
    expanded: null,
    savingDraft: false,
    savingItem: false,
    // match of selection in sidebar - used to highlight the passage
    match: null,
    // used to force a full rebuild of the editor
    key: 1,
  }
}

function buildQualityMetrics(question: string) {
  return [
    {
      name: 'Word Count',
      max: 500,
      min: 0,
      value: countWords(stripHTML(question) as string),
    },
  ]
}

export const slice = createSlice({
  name: 'author-cloze',
  initialState: getInitialState(),
  reducers: {
    set(state: State, action: PayloadAction<Partial<State>>) {
      Object.assign(state, action.payload)
    },
    // only for MCQ
    setItemQuestion(state: StateLoaded, action: PayloadAction<{ itemId: string; value: string }>) {
      const { itemId, value } = action.payload
      const item = state.items[itemId] as ItemMCQ
      item.currentContent.content = {
        ...item.currentContent.content,
        question: value,
      }
    },
    setAnswerValue(
      state: StateLoaded,
      action: PayloadAction<{ itemId: string; value: string; answerIndex: number }>,
    ) {
      const { itemId, value, answerIndex } = action.payload
      const item = state.items[itemId]
      const options = getOptions(item)
      options[answerIndex].value = value
    },

    setAnswerKey(
      state: StateLoaded,
      action: PayloadAction<{ itemId: string; value: AnswerStatus; answerIndex: number }>,
    ) {
      const { itemId, value, answerIndex } = action.payload
      const item = state.items[itemId]
      const options = getOptions(item)

      if (value === AnswerStatus.key) {
        options.forEach((answer) => {
          if (answer.status === AnswerStatus.key) {
            answer.status = AnswerStatus.distractor
          }
        })
      }

      options[answerIndex].status = value
    },
    statusUpdate(
      state: StateLoaded,
      action: PayloadAction<{ id: string; status: string; step: string }[]>,
    ) {
      const rootId = state.items.root.id

      for (const item of action.payload) {
        if (item.id === rootId) {
          state.items.root.status = item.status
          state.items.root.step = item.step
        } else if (state.items[item.id]) {
          state.items[item.id].status = item.status
          state.items[item.id].step = item.step
        } else {
          state.items[item.id] = {
            type: CLOZE,
            id: item.id,
            status: item.status,
            step: item.step,
            currentContent: {
              content: {},
              submodels: [],
            },
            originalContent: {
              submodels: [],
            },
          }
        }
      }
    },

    buildQualityMetrics(state: StateLoaded, action: PayloadAction<string>) {
      state.items.root.currentContent = {
        ...state.items.root.currentContent,
        qualityMetrics: buildQualityMetrics(action.payload),
      }
    },

    updateFromEditor(state: StateLoaded, action: PayloadAction<{ entities: any; html: string }>) {
      if (!state.items?.root) {
        return state
      }

      const { entities, html } = action.payload

      state.items.root.currentContent.content = {
        ...state.items.root.currentContent.content,
        stimulus: html,
        cloze_ids: entities.map((i) => i.id),
      }
      state.items.root.currentContent = {
        ...state.items.root.currentContent,
        qualityMetrics: buildQualityMetrics(html),
      }

      for (const { id, text, index } of entities) {
        const item = state.items[id] as ItemCloze

        if (!item) {
          state.items[id] = {
            id,
            type: CLOZE,
            status: 'NOT_STARTED',
            step: 'AI',
            index,
            currentContent: {
              ...state.items[id]?.currentContent,
              content: {
                options: [{ value: text, status: AnswerStatus.key }],
              },
            },
            originalContent: {
              ...state.items[id]?.originalContent,
              submodels: [],
            },
          }
        } else {
          const answer = item.currentContent.content?.options?.find(
            (i) => i.status === AnswerStatus.key,
          )

          if (answer && item.currentContent.content?.options) {
            answer.value = text
          }

          item.index = index
        }
      }

      return state
    },

    discardItems(state: StateLoaded) {
      if (!state.items) return

      state.items.root.currentContent.comments = state.items.root.savedContent?.comments || []

      for (const item of Object.values(state.items)) {
        if (item.currentContent.content && item.savedContent) {
          item.currentContent.content = { ...item.savedContent.content }
        }
      }
    },
    resetSubmodels(state: StateLoaded) {
      for (const [key, values] of Object.entries(state.items)) {
        if (key)
          Object.assign(state.items[key], {
            currentContent: {
              ...state.items[key].currentContent,
              submodels:
                values.originalContent?.submodels ||
                values.savedContent?.submodels ||
                values.currentContent.submodels,
            },
          })
      }
    },

    preRegenerateRoot(state: StateLoaded) {
      const { root } = state.items
      root.currentContent.content.stimulus = undefined
      root.currentContent.content.cloze_ids = []
      root.currentContent.qualityMetrics = undefined
      root.status = 'NOT_STARTED'
      root.step = 'AI'
    },

    preRegenerateItem(state: StateLoaded, action: PayloadAction<{ itemId: string }>) {
      state.items[action.payload.itemId].status = 'NOT_STARTED'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunkActions.fetchItemSet.fulfilled, (state, action) => {
        Object.assign(state, action.payload)
      })
      .addCase(thunkActions.fetchItemSet.rejected, (state, action) => {
        state.fatalError = action.error.message
      })

    builder.addCase(thunkActions.fetchItemContent.fulfilled, (state: StateLoaded, action) => {
      const isRoot = state.items.root.id === action.payload.id

      if (isRoot) {
        Object.assign(state.items.root, action.payload)
      } else {
        Object.assign(state.items[action.payload.id], action.payload)
      }
    })

    builder.addCase(metadataThunk.saveSubmodels.fulfilled, (state: StateLoaded, action) => {
      if (!state.items) return
      for (const key of Object.keys(state.items)) {
        if (action.payload.selectedSubmodels) {
          _.set(state.items[key], 'currentContent.submodels', action.payload.selectedSubmodels)
        }
      }
    })

    // clear the cloze state when enter the page
    builder.addCase(routeEnter, (state: State, action) => {
      if (action.payload.path === '/author/cloze/:itemId') {
        Object.assign(state, getInitialState())
      }
    })

    builder.addCase(routeLeave, (state: State, action) => {
      if (action.payload.path === '/author/cloze/:itemId') {
        state.contentTab = 0
      }
    })
  },
})

export default slice
export const { actions } = slice
