import { gql, type TypedDocumentNode } from '@apollo/client'
import { getClient } from '@core/api/api-state'
import ErrorHandler from '@core/api/ErrorHandler'
import { getUserRole } from '@core/main-state'
import { addSnack } from '@core/snack/snack-state'

import { useProjectsState, type Project } from './projects-state'

export const INSERT_PROJECT: TypedDocumentNode<
  { project: Project },
  { projectData: { name: string } }
> = gql`
  mutation insertProject($projectData: project_insert_input!) {
    project: insert_project_one(object: $projectData) {
      name
      id
      access(where: { read: { _eq: true } }) {
        userId: user_id
        write
        download
      }
      ownerId: owner_id
      owner {
        id
        email
      }
    }
  }
`

export const createProject = async (
  name: string,
): Promise<
  { success: true; project: Project } | { success: false; reason: string; project: null }
> => {
  const client = getClient()
  const { customer } = getUserRole()
  const projectData = { name, customer_id: customer.id, can_author: true }

  try {
    const { data } = await client.mutate({
      mutation: INSERT_PROJECT,
      variables: { projectData },
      context: { role: 'AUTHOR' },
    })

    if (!data) {
      throw new Error('Something went wrong')
    }

    // insert the project into the state to be faster than the subscription
    useProjectsState.setState((prevState) => ({
      items: { ...prevState.items, [data.project.id]: data.project },
    }))

    return {
      success: true,
      project: data.project,
    }
  } catch (error) {
    if (error.message.includes('project_customer_id_name_key')) {
      const message = 'Folder Title already exists'

      return {
        success: false,
        reason: message,
        project: null,
      }
    }

    addSnack({
      message: error.message || 'Something went wrong',
      severity: 'error',
    })

    return {
      success: false,
      reason: error.message,
      project: null,
    }
  }
}

export const UPDATE_PROJECT: TypedDocumentNode<{ project: Project }, { id: string; name: string }> =
  gql`
    mutation updateProject($id: uuid!, $name: String!) {
      project: update_project_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
        name
        id
        access(where: { read: { _eq: true } }) {
          userId: user_id
          write
          download
        }
        ownerId: owner_id
        owner {
          id
          email
        }
      }
    }
  `

export const updateProject = async (variables: {
  id: string
  name: string
}): Promise<
  { success: true; project: Project } | { success: false; reason: string; project: null }
> => {
  const client = getClient()

  try {
    const { data } = await client.mutate({
      mutation: UPDATE_PROJECT,
      variables,
    })

    if (!data) {
      throw new Error('Something went wrong')
    }

    return {
      success: true,
      project: data.project,
    }
  } catch (error) {
    if (error.message.includes('project_customer_id_name_key')) {
      const message = 'Folder Title already exists'

      return { success: false, reason: message, project: null }
    }

    addSnack({
      message: error.message || 'Something went wrong',
      severity: 'error',
    })

    return { success: false, reason: error.message, project: null }
  }
}

export const GET_PROJECTS: TypedDocumentNode<{ project: Project[] }> = gql`
  subscription get_projects {
    project(where: { can_deliver: { _eq: true } }, order_by: { name: asc }) {
      name
      id
      access(where: { read: { _eq: true } }) {
        userId: user_id
        write
        download
      }
      ownerId: owner_id
      owner {
        id
        email
      }
    }
  }
`

export type AccessOptions = {
  download: boolean
  write: boolean
  read: boolean
}

export type ProjectUserAccess = AccessOptions & {
  customerId: string
  projectId: string
  userId: string
}

export type User = {
  email: string
  id: string
  lastName: string
  firstName: string
  access: ProjectUserAccess[]
}

export const GET_PROJECT_USERS: TypedDocumentNode<{ users: User[] }, { projectId: string }> = gql`
  query projectUsers($projectId: uuid!) {
    users: user(where: { role: { _eq: "User" } }, order_by: { created_at: desc, first_name: asc }) {
      id
      email
      firstName: first_name
      lastName: last_name
      access: project_access(where: { project_id: { _eq: $projectId } }) {
        projectId: project_id
        userId: user_id
        download
        read
        write
      }
    }
  }
`
export const UPDATE_PROJECT_USER: TypedDocumentNode<
  { projectUser: ProjectUserAccess },
  ProjectUserAccess
> = gql`
  mutation updateProjectUserAccess(
    $customerId: uuid!
    $projectId: uuid!
    $userId: String!
    $read: Boolean
    $write: Boolean
    $download: Boolean
  ) {
    projectUser: insert_project_user_one(
      object: {
        customer_id: $customerId
        project_id: $projectId
        user_id: $userId
        download: $download
        read: $read
        write: $write
      }
      on_conflict: { constraint: project_user_pkey, update_columns: [download, read, write] }
    ) {
      customerId: customer_id
      projectId: project_id
      userId: user_id
      download
      read
      write
    }
  }
`

export const updateProjectUser = async (variables: ProjectUserAccess) => {
  const context = { headers: { 'x-hasura-role': 'customer_admin' } }
  const client = getClient()

  try {
    const { data } = await client.mutate({
      mutation: UPDATE_PROJECT_USER,
      variables,
      context,
    })

    return data?.projectUser
  } catch (error) {
    const { message } = ErrorHandler(error) as any
    addSnack({ severity: 'error', message })
  }
}
