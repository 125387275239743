import ErrorHandler from '@core/api/ErrorHandler'
import * as contentVersionQueries from '@core/content-version/content-version-queries'
import { getUserRole } from '@core/main-state'
import { addSnack } from '@core/snack/snack-state'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const saveSubmodels = createAsyncThunk<
  { selectedSubmodels: string[] },
  { selectedSubmodels: string[]; itemId: string }
>('metadata/saveSubmodels', async ({ selectedSubmodels, itemId }, { extra }) => {
  const { role } = getUserRole()

  try {
    const variables: contentVersionQueries.UpdateContentVersionsInput = {
      rootId: itemId,
      items: [
        {
          item_id: itemId,
          submodels: selectedSubmodels,
        },
      ],
    }

    await extra.client.mutate({
      mutation: contentVersionQueries.UPDATE_CONTENT_VERSIONS,
      variables,
      context: { role },
    })

    return { selectedSubmodels }
  } catch (error) {
    console.error('error while saving submodels', error)

    addSnack({
      message: `Error overriding submodels: ${error.message}`,
      severity: 'error',
    })

    throw error
  }
})

export const saveMetadata = createAsyncThunk(
  'metadata/saveMetadata',
  async (_, { extra, getState }) => {
    const { externalMetadata, itemId } = getState().metadata
    const { role } = getUserRole()

    if (!itemId || !externalMetadata) {
      console.warn('saveMetadata - missing itemId', { itemId, externalMetadata })
      return
    }

    try {
      const variables: contentVersionQueries.UpdateContentVersionsInput = {
        rootId: itemId,
        items: [
          {
            item_id: itemId,
            external_metadata: externalMetadata,
          },
        ],
      }

      await extra.client.mutate({
        mutation: contentVersionQueries.UPDATE_CONTENT_VERSIONS,
        variables,
        context: { role },
      })
    } catch (error) {
      ErrorHandler(error)

      addSnack({
        message: `Error overriding submodels: ${error.message}`,
        severity: 'error',
      })

      throw error
    }
  },
)
