import { gql, TypedDocumentNode } from '@apollo/client'

import type { Permission } from './users-types'

export const GET_USERS = gql`
  subscription getUsers($userId: String!) {
    users: user(where: { id: { _neq: $userId } }, order_by: { email: asc }) {
      id
      email
      role
      firstName: first_name
      lastName: last_name
      lastAccess: last_access
      createdAt: created_at
      customerId: customer_id
    }
  }
`

export const GET_ALL_PROJECTS = gql`
  subscription getAllProjects {
    project(where: { can_deliver: { _eq: true } }, order_by: { created_at: desc }) {
      id
      owner {
        id
        email
      }
      access {
        read
        user {
          id
          email
        }
      }
    }
  }
`

export type UpdateUserInput = {
  createPasswordLink?: boolean
  user: {
    id: string
    first_name: string
    last_name: string
    role?: string
  }
}

export type UpdateUserOutput = {
  email?: string
  ticket?: string
  status: 'success' | 'error'
  message?: string
  loc?: string
}

export const GET_AI_MODEL_PERMISSIONS: TypedDocumentNode<{ permissions: Permission[] }> = gql`
  subscription getAiModelPermissions {
    permissions: ai_model_permission {
      id
      userId: user_id
      read
      trustVote: trust_vote
      possibleKeys: possible_keys
      rationales
      pretest_key
      pretest_content
      pretest_style
      pretest_bias
      aiModelInternalName: ai_model_internal_name
    }
  }
`

export type RawPermission = {
  user_id: string
  ai_model_internal_name: string
  read: boolean
  trust_vote: boolean
  possible_keys: boolean
  rationales: boolean
}

export const UPSERT_AI_MODEL_PERMISSIONS: TypedDocumentNode<
  { result: { returning: Permission[] } },
  { items: RawPermission[] }
> = gql`
  mutation upsert_model_permissions($items: [ai_model_permission_insert_input!]!) {
    result: insert_ai_model_permission(
      objects: $items
      on_conflict: {
        constraint: unique_user_internal_name
        update_columns: [
          read
          trust_vote
          possible_keys
          rationales
          pretest_bias
          pretest_content
          pretest_key
          pretest_style
        ]
      }
    ) {
      affected_rows
      returning {
        id
        userId: user_id
        read
        trustVote: trust_vote
        aiModelInternalName: ai_model_internal_name
        possibleKeys: possible_keys
        rationales
      }
    }
  }
`
