import { useApi } from '@core/api'
import { minute } from '@core/constants/timeouts'
import { useQuery } from '@tanstack/react-query'

import type { AiModel } from './author-types'

export const authorKeys = {
  aiModelList: ['author', 'ai-models'],
}

// @TODO:
// possible improvement to add option to `select` certain fields
// see https://tanstack.com/query/latest/docs/framework/react/reference/useQuery
//    then find "select: (data: TData) => unknown"
export const useAiModels = () => {
  const api = useApi()

  return useQuery({
    queryKey: authorKeys.aiModelList,
    queryFn: async () => {
      const response = await api.listAiModels({ throwOnError: true })
      return response.data as unknown as AiModel[]
    },
    staleTime: minute * 5,
  })
}
